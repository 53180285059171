import React from 'react'
import { twMerge } from 'tailwind-merge'
import spriteUrl from '../../../assets/icons/outline.svg?url'
import solidSpriteUrl from '../../../assets/icons/solid.svg?url'
import type { IconProps } from './generated'

export const Icon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & IconProps>(
  ({ name, className, type = 'outline', ...props }, ref) => {
    const fullSpriteUrl = type === 'outline' ? spriteUrl : solidSpriteUrl

    return (
      <svg {...props} className={twMerge('h-6 w-6 flex-shrink-0', className)} ref={ref}>
        <use xlinkHref={`${fullSpriteUrl}#${name}`} />
      </svg>
    )
  },
)
